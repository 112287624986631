const the_encroaching_loneliness = `
ah, the stage. 

i believe it was shakespeare that said all the world's a stage and we merely actors. but today, i perform in the meta, an act within an act. me, with my multicolored hair and chalk white skin. i am the actor within the act within the act. im a professional. i walk onto the stage briskly and with purpose. staring at the audience i pause. smile. smiling is important. smile. 

i conjure various balls from thin air and i know the audience is impressed but they make no show of it. why would they? i am the actor. i make the show. slowly and metronomically the balls rise and fall. the audience is impressed, i know it! complacency. a slow and treacherous mind-killer. tragedy falls upon me as it has many times before and my nose falls off. my crimson round and shiny nose tumbles to the floor. i put my hands to my face in shock. that's the end for me i think. the audience points at me and laughs. i curl the corners of my mouth. smile. i already told you it's important to smile. 

i try to look at the audience but my eyes aren't there. where are my eyes please someone tell me where did my eyes go. im angry now. what a cruel audience! terrible! how could they be laughing at a time like this, at a man with hollow eyes and his nose pressed on the floor. but i chuckle, haha! i get it! they are not laughing at me or my eyes or my nose. they are laughing at the juxtaposition, the dichotomy, the visceral absurdity of a man smiling and having hollow eyes. that's a good joke! i'm satisfied. my existence revolves around making people smile. laughter? well, i think i'm due for a bonus at the end of the year. 

but i did not deserve this so turmoil begins to infiltrate my insides. this was just a coincidence. i did not plan for it. the light of god shone down and heated up the air around me, causing it to rise. it simply cushioned me and lifted it with me. but god was not aiming at me. no, only the the air around me. i happened to be there, and for that i was rewarded. that's good enough for me. the turmoil stops. i think, what a life i lead, rewarded for existence! 

the laughter of the audience begins to die down. the absurdity of the smiling, eyeless man no longer. it has become accepted in the world and now i am commonplace. 

it has become very quiet, and i know what that means. the encroaching loneliness. a terrible, terrible thing i thought i had gotten away from but it followed me all the way here! i thought when i had finished my job and i had made everyone smile it would go away but i am stuck with it because of that damn peddler! 

i once struck a deal with a peddler, i traded the very ground beneath my feet for water because i was parched. a thirst so great my throat was ripped from dryness and strain. but even after assessing my condition, he told me this was a bad trade, because i was surely to drown. i told him this was bad business practice and he should find a new career. but the peddler was right, and i did drown. and deep within there was calmness. and as i stared at the calmness, and it stared back i felt truly serene. 

take that you damn peddler! 

but the peddler was wise and for everything good that happens there must be something twice as bad to make the world balanced. that's when it took hold of me. 

imprinted itself into my very essence and soul. 

the encroaching loneliness.

that encroaching loneliness.
`

export default the_encroaching_loneliness
